<script>
    // import bookingUtils from "../../../utils/booking/bookingUtils";
	import {mapActions} from "vuex";
    import accountApi from "../../api/accountApi"
    export default {
        name: "loginWithSSO",
        data: () => {
          return {
            loginStatus: "",
          }
        },
        created() {
            this.toGetLoginInfo()
        },
		methods: {
			...mapActions('account', ['updateLoginInfo']),
			toGetLoginInfo() {
				accountApi.getOwnInfo().then(res => {
					let loginAccount = res.data
					sessionStorage.setItem('logedIn', true);
					sessionStorage.setItem('accountInfo', JSON.stringify(loginAccount));
					sessionStorage.setItem('requestedCurrency', loginAccount.requestedCurrency);
					sessionStorage.setItem('loginTime', new Date());
					let sdate1 = new Date();
					sdate1.setMinutes(sdate1.getMinutes() + 30);
					sessionStorage.setItem('expiredTime', sdate1);
					
					this.updateLoginInfo({loginRS: loginAccount})
					//路由跳转
					this.$router.push(
						{ path: "/rail" }
					)
				}).catch(error => {
					console.log(error)
					this.$router.push(
						{ path: "/login" }
					)
				})
			}
		},
	}
</script>
        
<template>
	<div class="mt-5 d-flex justify-content-center">
		<h5 class=""><b-spinner large label="Loading..."></b-spinner></h5>
	</div>
</template>